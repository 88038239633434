@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-header: 20 42 76;
    --color-button: 20 42 76;
    --color-button-hover: 237 106 90;
    --color-button-text: 255 255 255;
    --color-button-text-hover: 255 255 255;
    --color-body: 255 251 239;
    --color-footer-text: 111 111 109;
    --font-body: 'Outfit', sans-serif;
    --font-size-body: 22px;
    --font-button: 'Outfit';
    --font-size-button: 22px;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;500;600&display=swap');

a[type='button'] {
  background-color: theme('colors.button');
  color: theme('colors.button-text');
  font-family: theme('fontFamily.button');
  font-size: theme('fontSize.button');
  padding-top: 15px;
  padding-bottom: 15px;
}

a[type='button']:hover {
  background-color: theme('colors.button-hover');
  color: theme('colors.button-text-hover');
}

input[type='text'] {
  font-size: 18px;
  margin-bottom: 5px;
}

footer {
  color: theme('colors.footer-text');
}

body {
  background-color: theme('colors.body');
  font-family: theme('fontFamily.body');
  font-size: theme('fontSize.body');
}

.video {
  position: relative;
  padding-bottom: 0;
  padding-top: 0;
  height: 0;
  overflow: hidden;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
